import React from 'react';
import {
  Link,
} from "react-router-dom";
import './main.css';

import images   from './images.json';
import demolist from './demolist.json';

import HeroBlock        from './HeroBlock';
import WelcomeBlock     from './WelcomeBlock';
import TestimonialBlock from './TestimonialBlock';
import ListingSection   from './ListingSection';
import FeaturesBlock    from './FeaturesBlock';
import ContactBlock     from './ContactBlock';

// <TestimonialBlock />
const MainContent = () => (
  <>
    <HeroBlock />
    <WelcomeBlock />
    <ContactBlock />
  </>);

const data = {
  navbarDisplay : true,
  basebarHidden : true,
  order         : 0 ,
  icon:   () => ( <> <i className="far fa-address-card fa-lg"></i><br/>About </> ),
  // info:   () => ( <Link to="/" className="navbar-brand"><div className="nav-brand"><img alt="" src={"img/forbesnpresdev/NPR-F_stacked_sidexside_black-01_edit3.svg"} /> </div></Link> ),
  info:   () => ("Home"),
  render: () => <MainContent />
};
export default data;
