import React, {useState, useEffect} from 'react';
import {Link}   from "react-router-dom";
import Modal    from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

import JSONForm  from '../Components/JSONForm';
import {getUrl}  from '../Helpers';

const ImagesModal =({images, show, onHide}) => {
//https://github.com/react-bootstrap/react-bootstrap/issues/3480#issuecomment-474061945
// Modal inside div with onClick to allow closing
  return (
    <div onClick={e=>e.stopPropagation()}>
    <Modal
      show    ={show}
      onHide  ={()=>onHide()}
      onExit  ={()=>onHide()}
      backdrop={true}
      size    ="xl"
      style   ={{zIndex: "9999"}}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <div className="property-list-column" >
        <Carousel nextLabel="nest" prevLabel="prov"
          prevIcon={<i className="fas fa-chevron-circle-left"  style={{fontSize: "4em"}}></i>}
          nextIcon={<i className="fas fa-chevron-circle-right" style={{fontSize: "4em"}}></i>}
        >
          {Array.isArray(images) && images.map((i,n) =>
            <Carousel.Item key={n}>

              <div className="property-list-column">
                <div className="flex-column" style={{marginBottom: "-2em"} /*fix to allow indicators overlay on top of image */}>
                  <div className="listing-photo-link" style={{backgroundImage: `url("https://cdn.resize.sparkplatform.com/mt/640x480/true/${i.photo}-o.jpg")`}}>
                    <img alt="" src="img/transparent-list-box.png"/>
                  </div>
                </div>
              </div>

            </Carousel.Item>
          )}
        </Carousel>
      </div>
      <button
        style={{
          borderRadius : "100%",
          width        : "2em",
          height       : "2em",
          position     : "absolute",
          right        : "-17px",
          top          : "-22px",
          fontSize     : "2em",
          zIndex       : "1000",
        }}
        onClick={(e)=>{e.preventDefault(); onHide()}}>
          <i className="fas fa-times"></i>
      </button>
    </Modal>
  </div>
  );
}


const PropertyDetail = ({data, photos, onClick}) => {
  const [Response, setResponse] = useState(null);
  const sendData = (data) => {
    console.log("inquiry", data);

    fetch(getUrl("email/index.php", "contact"),
      {
        method: "POST",
        body: JSON.stringify(data)
      })
    .then(x=>x.json())
    .then(x=>{
              console.log("loading", x );
              setResponse({message: x.result});
    });
  }

  if(!data || !photos) return "Loading...";
  return (
  <main className="inside-main">
    <div className="container">

      <div className="align-items-md-start d-md-flex flex-md-row flex-md-wrap justify-content-md-start row">
        <div className="col-md-12"><h1 className=" pb-4 text-center">{data.address}</h1></div>
      </div>

      <div className=" mt-4 row">
        <div className="col-md-6 order-2 order-md-first">
          <div className="row">
            <div className="col-3 col-md-3"><h3 className="text-center">{data.beds}</h3>  <p className="property-stat-label"> Bed</p></div>
            <div className="col-3 col-md-3"><h3 className="text-center">{data.baths}</h3> <p className="property-stat-label"> Bath</p></div>
            <div className="col-3 col-md-3"><h3 className="text-center">{data.sqft}</h3>  <p className=" property-stat-label">Sq.Ft.</p></div>
            <div className="col-3 col-md-3"><h3 className="text-center">{data.acres}</h3> <p className="property-stat-label"> Acres</p></div>
          </div>

          <h6 className=" pb-2 pt-3 text-center">Description:</h6>
          <p>{data.description}</p>

          <div className="row">
            <div className="col-md-12">
              <JSONForm
                header     =  "Inquire About This Property"
                submitLabel=  "Send Inquiry"
                sendData   =  {sendData}
                fields     =  {
                                {
                                  "name"    : {type: "text",     label: "Your Name",  placeholder: "Enter name..." },
                                  "email"   : {type: "email",    label: "Your Email", placeholder: "Enter email..." },
                                  "phone"   : {type: "phone",    label: "Your Phone", placeholder: "Enter phone..." },
                                  "message" : {type: "textarea", label: "Message:",   placeholder: "Enter your message..." }
                                }
                              }
              />
              {/*
              <form role="form">
                <div className=" input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <input type="text" name="firstname" id="firstname-field" className="form-control" placeholder="First Name" />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <input type="text" name="lastname" id="lastname-field" className="form-control" placeholder="Last Name" />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </div>
                  </div>
                  <input type="email" name="email" id="email-field" className="form-control" placeholder="Email Address" />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-phone"></i>
                    </div>
                  </div>
                  <input type="text" name="phone" id="phone-field" className="form-control" placeholder="Phone number" />
                </div>
                <div className="form-group">
                  <label htmlFor="formInput21">Message:</label>
                  <textarea className="form-control" rows="3" id="formInput21"></textarea>
                </div>
              </form>
              <a href="tel:406-253-3013"><button className="btn btn-block btn-dark font-weight-bold mt-4 text-white">Send Inquiry</button></a>
            */}
            </div>
            <h1>{Response && Response.message ? Response.message : ""}</h1>
          </div>
        </div>
        <div className=" col-md-6 mb-3 order-first order-md-2">
          <div className="card card-cascade wider reverse">
            {/*<!-- Card image -->*/}
            <div className="view view-cascade overlay">
              <Carousel
              controls  ={true}
              indicators={false}
              interval={null}
              prevIcon  ={<i className="fas fa-chevron-circle-left"  style={{fontSize: "2em"}}></i>}
              nextIcon  ={<i className="fas fa-chevron-circle-right" style={{fontSize: "2em"}}></i>}
              >
              {Array.isArray(photos) && photos.map((i,n) =>
              <Carousel.Item key={n} onClick   ={
              onClick
              ? ()=>onClick(true)
              : ()=>console.log("onClick")} >
              <img
                className="card-img-top image-fit img-fluid"
                alt=""
                src={`https://cdn.resize.sparkplatform.com/mt/640x480/true/${i.photo}-o.jpg`}/>
              </Carousel.Item>
              )}
              </Carousel>
            </div>
            {/*<!-- Card content -->*/}
            <div className="card-body card-body-cascade text-center">
              {/*<!-- Title -->*/}
              <h4 className="card-title font-weight-bold">${Number(data.list_price).toLocaleString('en-US')}</h4>
              {/*<!-- Subtitle -->*/}
              <p className="font-weight-bold indigo-text mb-0">MLS#{data.mls_number}</p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-lg-12"
              style={{}}
              >
              <a style={{width: "90%", marginLeft: "auto", marginRight: "auto"}} href={`details?mls=${data.mls_number}#table-detail`} type="button" role="button" className=" btn btn-block btn-secondary ">View Details</a>
            </div>
            {/*
            <div className="col-md-6 col-lg-6" style={{visibility: "hidden"}} >
            <button type="button" className="btn btn-block btn-secondary pl-3 pr-3" data-toggle="modal" data-target="#prop-map-modal">See On Map</button>
          </div>
          */}
          <div className="col-md-12 col-lg-12" style={
          data.tourLink == "N/A" ? {visibility: "hidden"} : {}
          }>
          <button
          className="btn btn-block btn-secondary"
          style={{width: "90%", marginLeft: "auto", marginRight: "auto"}}
          type="button"
          onClick={()=>window.open(data.tourLink, "_blank")}
          >{data.tourLink == "N/A" ? "No Tour Available": "Tour" }</button>
        </div>
        {/*
        <div className="col-md-6 col-lg-6" style={{visibility: "hidden"}} >
        <button type="button" className="btn btn-block btn-secondary" data-toggle="modal" data-target="#social-modal">Share</button>
      </div>
      */}
    </div>
    </div>
    </div>
    </div>
  </main>
  );
}

const tableList = (data) => {
  if(!data) return;
  return Object.entries(data).map( (entry, i) => {
    switch(entry[0]){
      case "IDX cooperating listing agent": return ""; //omit from table
      case "Construction":
      case "Lot Information":
      case "Waterfront":
      case "Road":
        return (
          <tr key={i}>
            <th scope="row">{entry[0]}:</th>
            <td>
              { Object.entries(entry[1]).map(n => <>
                {n[0]}: {n[1]}
                <hr className="mb-0 mt-0 pb-0 pt-0" />
              </> ) }
            </td>
          </tr>
        );
      case "Equipment":
      case "Utilities":
        return (
          <tr key={i}>
            <th scope="row">{entry[0]}:</th>
            <td>{entry && entry[1] ? entry[1].join(', ') : ""}</td>
          </tr>
        );
      case "Price": return (
        <tr key={i}>
          <th scope="row">Price:</th>
          <td>${ Number(entry[1]).toLocaleString('en-US') }</td>
        </tr>
      );
      default:
        // console.log("default", entry[0], typeof entry[1]);
        return (
        <tr key={i}>
          <th scope="row" className="w-25">{entry[0]}:</th>
          <td className="col-12 w-75">{entry[1]}</td>
        </tr> );
      }
  });
}

const TableDetail = ({data}) => (
  !data
  ? ""
  :
  <section className="details-section" id="details-section" style={{marginTop: "55px", padding: "130px 0 0 0"}}>
      {/*<!-- SECTION MARKUP FOR RESIDENTIAL LISTINGS -->*/}
      <div className="container" id="residential-details">
          <div className="row">
              <div className="col-md-4 details-panel-col" style={{paddingTop: "20px", border: ".02rem solid rgba(0, 0, 0, 0.1)"}}>
                <a id="table-detail">
                  <h4>Essential Information</h4>
                </a>
                  <table className="table">
                      <tbody>{tableList(data.essential)}</tbody>
                  </table>
              </div>
              <div className="col-md-4 details-panel-col" style={{paddingTop: "20px", border: ".02rem solid rgba(0, 0, 0, 0.1)"}}>
                  <h4>Interior Features</h4>
                  <table className="table">
                      <tbody>
                        {tableList(data.Interior)}
                      </tbody>
                  </table>
                  <hr />
                  <h4>Additional Listing Details:</h4>
                  <table className="table">
                      <tbody>
                        {tableList(data.details)}
                      </tbody>
                  </table>
              </div>
              <div className="col-md-4 details-panel-col" style={{paddingTop: "20px", border: ".02rem solid rgba(0, 0, 0, 0.1)"}}>
                  <h4>Exterior Features</h4>
                  <table className="table">
                      <tbody>
                        {tableList(data.Exterior)}
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </section>
);

const AgentDetail = ({data}) => (
  !data
  ? ""
  :
    <div className="container mt-4">
      <div className="row">
        <div className="col-12 prop-details-legal">
          <h6>IDX cooperating listing agent: {data.listing_member_name||"ERROR"} - cooperating listing office: {data.listing_office_name||"ERROR"} - WF ({data.listing_member_address||"ERROR"})</h6>
          <p className="small text-muted">
            The data relating to real estate on this web site comes in part from the
            Internet Data Exchange program of the Northwest Montana Association of  REALTORS &copy; Multiple Listing Service, Inc., added to this website on
            <b> {data.hr_list_date} </b>
            and was last updated
            <b> {data.hr_last_mod}</b>.
            All  information is deemed reliable but not guaranteed and should be  independently verified. All properties are subject to prior sale,  change, or withdrawal. Neither listing agents(s) nor National Parks  Realty shall be responsible for any typographical errors,  misinformation, or misprints, and shall be held totally harmless from  any damages arising from reliance upon these data. National Parks Realty  of Montana Agents are licensed to sell real estate in the state of  Montana. <br />&copy; 2021 Northwest Montana Association of REALTORS &copy; Multiple Listing Service, Inc.&nbsp;
          </p>
        </div>
      </div>
    </div>
);


const Component = ({query, location}) => {
  const [state,   setstate]   = useState({});
  const [details, setdetails] = useState({data: {main: {}, table: {}, agent: {} } });

  useEffect(() => {
    console.log("detail", query, location);

    fetch(getUrl("details/index.php"+location, "details"))
      .then(x=>x.json())
      .then(x=>{
        console.log("loading", x );
        setdetails({data: x});
      });
  }, [query, location] );


  if(!details.data) return "oops";

  return (
      <React.Fragment>
        <PropertyDetail data   ={details.data.main}
                        photos ={details.data.photos}
                        onClick={()=>setstate({show: true})}/>
        <TableDetail    data   ={details.data.table} />
        <AgentDetail    data   ={details.data.agent} />
        <ImagesModal    images ={details.data.photos}
                        show   ={state.show}
                        onHide ={(e)=>setstate({show: false})}/>
      </React.Fragment>
  );
};

const data = {
  basebarHidden : true,
  info:   () => {return ("Details"); },
  render: (query, location) => <Component query={query} location={location}/>,
  icon:   () => ( <> <i className="far fa-address-card fa-lg"></i><br/>About </> )
};

export default data;
