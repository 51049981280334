import React    from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import "./SearchDropdown.css";

const SearchDropdown=({id, label, text, options, onClick, onSelect, className})=>{
  // console.log("options: ", {label, text, options, onClick, className});
  const dropdownItems = (options)=>(options && Array.isArray(options)
      ? options.map ( (option, i)=>
                      <Dropdown.Item
                        className="dropdown-item"
                        id       ={option}
                        key      ={i}
                        eventKey ={option}
                        onClick  ={ onClick
                                      ? ( )=>onClick(i)
                                      : (e)=>console.log('on click not set', e) }
                      >{option}</Dropdown.Item>
                    )
      : "none"
  );

  return (
    <div className={className} onClick={e=>e.stopPropagation()}>
      <Dropdown >
        <Dropdown.Toggle className="btn btn-secondary btn-sm dropdown-toggle" id={id} >{text}</Dropdown.Toggle>
        <Dropdown.Menu   className="dropdown-menu" >                                   {dropdownItems(options)}</Dropdown.Menu>
      </Dropdown>
      {label}
    </div>
  );
};

export default SearchDropdown;
