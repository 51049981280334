
/*
Renders the property overview mini box, parses data as its received from api and presents in internal specific format
the format is internal and can be modified freely
the props called with are api directly and should respect the mls / DB format
*/

import React,
        {useState} from 'react';
import Carousel    from 'react-bootstrap/Carousel';

import {
  Link,
} from "react-router-dom";

//TODO: needs a proper "image loading or not found"
import imgnoworky from './imgnoworky.png';
import './OverviewBox.css';

const getListingUtilitiesIcons=(utility)=>{
  if(!utility) return "err: no util";
  if(utility.length==0) return "";
  return (
    <li className="list-group-item">Utilities
      {Array.isArray(utility) && utility.map((u, i) =>{
          //TODO: add the rest of the icons for known utilities
          switch(u){
            case "water"    : return  <span key={i} className="highlight-value" title="Water"> <i className="fas fa-faucet-drip" data-tooltip-position="top bottom"></i> </span>
            case "Electric" : return  <span key={i} className="highlight-value" title="Electric"> <i className="fas fa-bolt"></i> </span>
            case "Telephone": return  <span key={i} className="highlight-value" title="Telephone"> <i className="fas fa-phone-square"></i> </span>
            default         : return  <span key={i} className="highlight-value" title={u}> <i className="fas fa-question"></i><br/>{u.split(' ')[0]}</span>;
          }
      })}
    </li>
  );
}

const getListingTypeLabel=(type)=>{
  switch(type){
    case "": return "";
    case 'Land'                 : return <li className="li-land list-group-item">Land</li>;
    case 'Commercial'           : return <li className="li-comm list-group-item">Commercial</li>;
    case 'Multi Family'         : return <li className="li-multi list-group-item">Multi-Family</li>;
    case 'Business Opportunity' : return <li className="li-bizop list-group-item">Business Opp.</li>;
    //TODO: do these need their own color style ?
    case 'Residential'          :
    case 'Agricultural'         :
    case 'Misc Property'        :
    case 'Commercial Lease'     :
    default                     : return <li className="list-group-item" style={{textTransform: "uppercase"}}>{type}</li>;
  }
}

//HTML code goes here, receives parseData() result object as input
//{data.utilities} removed
//<ImagesModal mls={data.mls_number} images={data.imageList} show={state.show} onHide ={(e)=>setstate({show: false})}/>
//Modal and carrousel removed
const RealBox = ({data}) => {
  const frontStyle = (data.imageList && data.imageList[0] && data.imageList[0].photo //programming is awesome
                      ? {backgroundImage: `url('https://cdn.resize.sparkplatform.com/mt/640x480/true/${data.imageList[0].photo}-o.jpg')`}
                      : {backgroundImage: `url('${imgnoworky}')`} );

  return (
    <div  className="col-lg-3 col-md-4 property-list-column">
         <div className="flex-column"  >
          <div className="property-list-column" >
            <Link
              to={data.linkDetail}
              className="view-details-link">
              <div className="flex-column" style={{marginTop:"-15px", marginBottom: "-15px", borderStyle: "none"}}>
                <div className="listing-photo-link" style={
                    data.imageList && data.imageList[0]
                      ? {backgroundImage: `url("https://cdn.resize.sparkplatform.com/mt/640x480/true/${data.imageList[0].photo}.jpg")`}
                      : {backgroundImage: `url('${imgnoworky}')`}
                  }>
                  <img alt="" src="img/transparent-list-box.png"/>
                </div>
              </div>
            </Link>
          </div>

          <h5 className="price-badge">${data.cost}</h5>
          {data.address}
          <ul className="list-group">
            {data.listingType}
            {data.features}
            {data.TourLink}
          </ul>
          <div className="property-list-base">
            <span className="mlsnum">MLS#{data.mls_number}<br/></span>
            <Link
              to={data.linkDetail}
              className="view-details-link">
              <span>View Details&nbsp;&rarr;</span>
            </Link>
          </div>
       </div>
    </div>
  )
};


//TODO: figure out how much of this data is specific to the property listings and how much can be offloaded to api or elsewhere
const parseData = (data, images) => {
    let res = {
      cost       : Number(data.list_price).toLocaleString('en-US'),
      address    : <div className="address">{`${data.street_num||""} ${data.street_name||""} ${data.street_sfx||""}`}<br/>{data.city||""} </div>,
      listingType: getListingTypeLabel(data.property_type),
      features   : (!Array.isArray(data.features)
                     ? "N/A"
                     : data.features.map((n,i)=>
                        <li key={i} className="list-group-item">{n[0]}
                          <span className="highlight-value">{n[1]}</span>
                        </li>) ),
      utilities  : getListingUtilitiesIcons(data.utilities),
      mls_number : data.mls_number,
      linkDetail : `details?mls=${data.mls_number}`,
      imageList  : images[data.mls_number],
      TourLink   : data.TourLink=="N/A"
                    ? ""
                    : <li key={999} className="list-group-item">Has Tour</li>
    };

    return <RealBox key={data.mls_number} data={res} />;
}

const OverviewBox = ({data, images}) => (
  data && data.data && images
    ? Object.keys(data.data).map(
        (index) => parseData(data.data[index], images)
      )
    : "There is no data to show"
);


export default OverviewBox;
