import React, {useState, useEffect} from 'react';

import Component from './Component';

const data = {
  navbarDisplay : true,
  order         : 1 ,
  info          : ()      => {return ("Listings"); },
  render        : (query) => <Component query={query}  />,
  icon          : ()      => ( <> <i className="far fa-laptop-house fa-lg"></i><br/>Stephanie's Listings </>)
};

export default data;
