import React          from 'react';
import SearchSection  from './SearchSection';
import SearchDropdown from '../../Components/SearchDropdown';
import PagingSection  from './PagingSection';
import OverviewBox    from '../../Components/OverviewBox'

const limitListingTexts  = ["12 properties", "24 properties", "48 properties"];
const limitListingValues = ["12",            "24",            "48"];

const filterByTexts      = ["Date: New to Old", "Date: Old to New", "Price: High to Low", "Price: Low to High", "City: A to Z", "City: Z to A"]
const filterByValues     = ["no",               "on",               "hl",                 "lh",                 "az",           "za"];


const ListingsSection = ({listingData, images, onChange}) => {

  let filteredText  = (acronym) => filterByTexts[filterByValues.findIndex(x=>acronym==x)];
  let txtPageStart  = (Number(listingData.pageStart) * Number(listingData.searchLimit)+1) || 1;
  let txtPageEnd    = Math.min(
                        (txtPageStart+Number(listingData.searchLimit)-1),
                        listingData.listCount
                      ) || '1';

  const pagingComponent = (
    <PagingSection
      state   ={listingData}
      page    ={listingData.pageStart}
      lastPage={listingData.listCount/listingData.searchLimit}
      onClick ={  onChange
                    ? ({pageStart}) => {
                        console.log("rcv " + pageStart);
                        onChange( {pageStart   : pageStart >= 0 ? pageStart : 0})
                      }
                    : (x)=>console.log("PagingSection", x)
                }
    />
  );

  // console.log("onChange", onChange);

 // className="align-items-md-start d-md-flex flex-md-row flex-md-wrap justify-content-md-start "
  return (
  <>
    <div className="row">
      <SearchDropdown
        className="col text-left ml-4 "
        label    =<small>Showing <b>{txtPageStart}-{txtPageEnd}</b> of <b>{listingData.listCount||"1"}</b></small>
        text     ="page length"
        options  ={limitListingTexts}
        onClick  ={onChange
                   ? (e)=>onChange({searchLimit: limitListingValues[e]})
                   : (e)=>console.log("listing click", limitListingValues[e]) }/>

      {pagingComponent}

      <SearchDropdown
        className="col text-right mr-4 "
        label    =<small>Sorting by <b>{filteredText(listingData.filterBy)}</b></small>
        text     ="Filter by"
        options  ={filterByTexts}
        onClick  ={onChange
                   ? (e)=>onChange({filterBy: filterByValues[e] })
                   : (e)=>console.log('parent click from ', e) }/>
    </div>

    <div>
      <div className="justify-content-center main-property-list row"> <OverviewBox data={listingData} images={images} /> </div>
      {pagingComponent}
    </div>
  </>
  );
}

export default ListingsSection;

  /* when a listing OverviewBox is shown only once, the size width css breaks and shows too small making carousel do an weird */
  /* TODO: fix css to allow a single item of overviewBox */
  /*!listingData
    ? ""
    : Object.keys(listingData.data).length == 1
    <div style={{visibility: "hidden"}} className="col-lg-3 col-md-4 property-list-column">
      <div className="flex-column">
        <div className="property-list-column">
          <div className="flex-column">
            <a className="listing-photo-link" style={{backgroundImage: `url("https://cdn.resize.sparkplatform.com/mt/640x480/true/20210309162746834039000000.jpg")`}}>
            <img src="img/transparent-list-box.png"/></a>
          </div>
        </div>
      </div>
    </div>
*/
