import React, { useState } from 'react';
import {
  Switch,
  Route,
  Link,
  withRouter,
  useLocation
} from "react-router-dom";


import ScrollToTop from './scrollToTop';
import * as pieces from './pieces';
import appConfig   from './appConfig';


const HeaderNavigation = ({pieces}) => (
  <div id="navbar-wrapper" className="navbar-wrapper">
    <nav className="navbar navbar-bgt-md navbar-dark navbar-expand-lg " id="top-navbar">
      {appConfig.showLogo == true
        ? (
          <a className="navbar-brand" href="index.html">
            <div className="nav-brand">
              <img alt="logo" src={appConfig.header.logoUrl}/>
            </div>
          </a>
          )
        : ""}
      <button className="navbar-toggler" type="button"
          data-toggle   ="collapse"
          data-target   ="#navbarNavDropdown-5"
          aria-controls ="navbarNavDropdown-5"
          aria-expanded ="false"
          aria-label    ="Toggle navigation">
          <span className   ="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse " id="navbarNavDropdown-5">
        <ul className="ml-auto navbar-nav">
          { Object.keys(pieces)
            .filter( key       => pieces[key].navbarDisplay === true)
            .sort  ( (m,n)     => pieces[m].order-pieces[n].order )
            .map   ( (piece,i) =>
                <li key={i} className="nav-item"  data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link className="nav-link" to={piece}>{pieces[piece].info()}</Link>
                </li>
          )}
          <li className="nav-item">
            <a className="font-weight-bold nav-link tel-header"
              href={`tel:${appConfig.phone}`}>{appConfig.phone}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

const BaseNavigation = () => (
  <section id="base-nav" className=" pt-5">
    <div className=" container mb-5 mt-5">
      <ul className="justify-content-around nav text-center">
        { Object.keys(pieces)
          .filter( key       => pieces[key].basebarHidden !== true)
          .sort  ( (m,n)     => pieces[m].order-pieces[n].order )
          .map   ( (piece,i) =>
              <li key={i} className="nav-item">
                <Link className="base-nav-link nav-link" to={piece}>{pieces[piece].icon()}</Link>
              </li>
          )}
      </ul>
    </div>
  </section>
);


const FooterItemsBig = () => (
  !(appConfig.footer.items && Array.isArray(appConfig.footer.items))
   ? ""
   : appConfig.footer.items.map( (item, i) =>
    <div className="card" key={i}>
      <div className="card-body">
        <div className="card-text-icon"><i className={item.icon}></i></div>
        <div className="card-text">
          <b style={{fontSize:"1.2em"}}>{item.label}</b><br/>
          <p className={"footer-contact-text "+(item.label=="Email"?"text-nowrap":"")}>
            {item.body.map( (x,i)=><React.Fragment key={i}>{x}<br/></React.Fragment> ) }
          </p>
        </div>
      </div>
    </div>
  )
);

const FooterItemsSmall = () => (
  !(appConfig.footer.items && Array.isArray(appConfig.footer.items))
    ? ""
    : appConfig.footer.items.map( (item, i) =>
  <div className="col-md-4 footer-xs-contact">
    <h4><i className={item.icon}></i>{item.label}</h4>
    <p className="text-uppercase">{item.body.join(" ")}</p>
  </div>
  )
);

const Footer = () => (
  <footer>
  {/*
    <div className="row agent-brand" id="agent-brand">
      <div className="col-md-4 offset-md-4">
        <img className="image-fit img-fluid" alt="" src={appConfig.footer.imageUrl} />
      </div>
    </div>
    */}
    <div className="row contact-blocks" id="contact-block">
      <div className="container-fluid">

        <div className="align-items-center d-md-flex d-none flex-md-row justify-content-around mb-3 row">
          <div className="card-deck">
            <FooterItemsBig />
          </div>
        </div>

        <div className="d-block d-md-none row">
          <div className="col-md-4 footer-xs-contact">
            <h4><i className="far fa-phone fa-lg"></i> Address</h4>
            <p className="text-uppercase">601 Spokane Ave. Whitefish, Montana 59937</p>
          </div>
          <div className="col-md-4 footer-xs-contact">
            <h4><i className="far fa-map-marker fa-lg"></i> Phone</h4>
            <p className="text-uppercase">Office: (406) 862-0118</p>
            <p className="text-uppercase">
                Mobile: (406) 253-3013 </p>
          </div>
          <div className="col-md-4 footer-xs-contact">
              <h4><i className="far fa-envelope fa-lg"></i> Email</h4>
              <p className="text-uppercase small">{appConfig.email}</p>
          </div>
        </div>

      </div>
    </div>


    <div className="row fine-print" id="fine-print">
      <div className="col-md-12">
        <p>© 2004 - 2021 National Parks Realty of Montana, LLC. All Rights Reserved. A Member of the Montana Regional Association of REALTORS® and its Multiple Listing Service (MRMLS). National Parks Realty of Montana Agents are licensed to sell real estate in the State of Montana.</p>
      </div>
    </div>

  </footer>

);

const App = (props) => {
  let location = useLocation();
  const [query, setQuery] = useState(parseQuery());
  // const [scrollPosition, setScrollPosition] = useState(0);
  //
  // const handleScroll = () => {
  //     const position = window.pageYOffset;
  //     setScrollPosition(position);
  // };
  //
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //
  //   return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  function parseQuery(){
    let _query = {owner: appConfig.owner};
    for (let params of new URLSearchParams(location.search).entries()) {
        _query[params[0]] = params[1];
    }
    return _query;
  }

  // useEffect(() => history.listen(() => {
  //     // do something on route change
  //     // for my example, close a drawer
  //     console.log(location, props)
  //
  // }), [])

/*TODO: switch route exact path="/" for all cases where it loads to main, should not show image header and be black instead */
  return (
    <ScrollToTop history={props.history}>

      <HeaderNavigation pieces={pieces} />

      <Switch>
        <Route exact path="/">           </Route>
        <Route exact path="/index.html"> </Route>
        <Route exact path="/main">       </Route>
        <Route path="*"><section className="inside-header" id="inner-header"></section></Route>
      </Switch>

      <Switch>
        {
          Object.keys(pieces).map((piece, i) =>
            <Route key={i} path={`/${piece}`} render={ ()=>pieces[piece].render(query, location.search) } />
          )
        }
        <Route path="/" component={ pieces.main.render } />
      </Switch>

      <BaseNavigation />
      <Footer />
    </ScrollToTop>
  );
};

export default withRouter(App);
