import React, {useState, useEffect} from 'react';
import SearchDropdown    from '../../Components/SearchDropdown';
import ModalButton       from '../../Components/ModalButton';
import Col               from 'react-bootstrap/Col';
import Button            from 'react-bootstrap/Button';
import cities_static     from './cities.json';

const SearchSection=({state, onChange, className, agentName})=>{
    /*
    19 apr
    basic search:
    - city
    - bedrooms
    - type land, residential
    - price (range)
    - acres
    */

  let price    =  ["Any", "less than 200k", "200k-300k", "300k-500k", "500k-700k", "700k-1M", "1M or more" ];
  let cities   =  cities_static;
  let types    =  ["Any", "Residential", "Land", "Multi Family"];
  let bedrooms =  ["Any", "1-3", "3-5", "5 or More"];
  let acres    =  ["Any", "less than 0.5","0.5-1.0","1.0-2.0","2.0-10.0","10.0 or more"];
  let fields   =  {
                    Price : {type:"dropdown", label:"Price Range",           text: state.search.Price, values: price   ||["All"] },
                    City  : {type:"dropdown", label:"Select City to Search", text: state.search.City,  values: cities  ||["All"] },
                    Type  : {type:"dropdown", label:"Property Type",         text: state.search.Type,  values: types   ||["All"] },
                    Beds  : {type:"dropdown", label:"Number of Beds",        text: state.search.Beds,  values: bedrooms||["All"] },
                    Acres : {type:"dropdown", label:"Lot Area in Acres",     text: state.search.Acres, values: acres   ||["All"] },
                  }
  let values   =  {
                    // Pricestart : fields.Pricestart ? fields.Pricestart.values[state.search.Pricestart] : "0",
                    // Priceend   : fields.Priceend   ? fields.Priceend  .values[state.search.Priceend]   : "0",
                    Price      : fields.Price.values[state.search.Price],
                    City       : fields.City .values[state.search.City],
                    Type       : fields.Type .values[state.search.Type],
                    Beds       : fields.Beds .values[state.search.Beds],
                    Acres      : fields.Acres.values[state.search.Acres],
                  }


const dochange=(e)=>{
  console.log("state.owner",state.owner);
  return onChange
            ? onChange({owner: state.owner!="All"?"no":"yes"})
            // ? (e)=>onChange({search: e.search})
            : console.log(e)
}

  return (
        <div className={className} >

          <div className="mb-2 text-center">
            <Button
              className="btn btn-secondary w-50"
              onClick={dochange}>
              View Listings from {agentName}
            </Button>
          </div>

          <ModalButton
            className=" text-center"
            fields   ={fields}
            values   ={state.search}
            onChange ={onChange
                        ? (e)=>onChange({search: e.search})
                        : (e)=>console.log(e) }/>

        </div>

  ); //return

};

export default SearchSection;
