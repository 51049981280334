import React, { useState } from 'react';
import SearchDropdown    from './SearchDropdown';

import Dropdown from 'react-bootstrap/Dropdown';


const JSONForm = ({header, fields, submitLabel, sendData, defaultFormData}) => {
  const [FormData, setFormData] = useState(defaultFormData || {});

  if(!fields) return "Nothing to show";

  let fieldkeys = !fields ? [] : Object.keys(fields);

  const fieldToJSX = (key) => {
    let obj = fields[key];
    if(!(obj && obj.type)) return "None";
    switch(obj.type){
      case "maxmin":
        /* two  dropdowns, they automatically fix the max - min selection */
        return (
          <div className="form-group flex row m-2" key={key}>
            <span className="pr-2 w-50">{obj.label}</span>
            <SearchDropdown
              className="text-center w-25 minmax"
              text     ={obj.values[ FormData[key+"start"] ]}
              options  ={obj.values || ["no data"]}
              onClick  ={ (index, s)=>{
                            setFormData({...FormData, [key+"start"] : index})
                            console.log(index);
                          }
                        }
            />
            <SearchDropdown
              className="text-center w-25 minmax"
              text     ={obj.values[ FormData[key+"end"] ]}
              options  ={obj.values || ["no data"]}
              onClick  ={ (index)=>setFormData({...FormData, [key+"end"] : index}) }
            />
          </div>
        );

      case "dropdown":
        return (
          <div className="form-group  flex row m-2 f-w" key={key}>
            <span className="pr-2 w-50">{obj.label}</span>
            <SearchDropdown
              id={key}
              className="text-center w-50"
              text     ={FormData[key]}
              options  ={obj.values || ["no data"]}
              onClick  ={ (index)=>setFormData({...FormData, [key] : obj.values[index]}) }
              onSelect ={onSelectHandler}
            />
          </div>
        );

      case "textarea":
        return (
          <div className="form-group  flex row m-2" key={key}>
            <span className="pr-2 w-50">{obj.label}</span>
            <textarea
              id          = {key}
              rows        = "6"
              className   = "form-control"
              placeholder = {obj.placeholder}
              onChange    = { (e)=>setFormData({...FormData, [key] : e.target.value}) }
            ></textarea>
          </div>
        );

      case "phone":
      case "email":
      case "text":
        return (
          <div className="form-group  flex row m-2" key={key}>
            <span className="pr-2 w-25">{obj.label}</span>
            <input
              className  ="border form-control w-75"
              type       ={obj.type}
              id         ={key}
              placeholder={obj.placeholder}
              onChange   ={ (e)=>setFormData({...FormData, [key] : e.target.value}) }
            />
          </div>
        );

      default :    return obj.type+"field not implemented"
    }
  };

  const onSelectHandler = (i) => {
    console.log(i);
  }

  return (
    <>
      <h4 className="font-weight-bold m-4 text-uppercase text-center">{header}</h4>
      <form className="p-2">
        {fieldkeys.map( (k,i)=>fieldToJSX(k) )}
      </form>
      <button
        className="btn btn-secondary rounded-0 text-uppercase waves-effect waves-light m-3"
        onClick={
          sendData
            ? ()=>sendData(FormData)
            : ()=>console.table(FormData)
        }
      >{submitLabel}</button>
    </>
  );
}
// <br/>
// FormData: {JSON.stringify(FormData)}<br/>

export default JSONForm;
