import React, {useState, useEffect} from 'react';
import Modal             from 'react-bootstrap/Modal';
import JSONForm          from './JSONForm';
import {getUrl}          from '../Helpers';

// const ModalButton = ({className, onHide, onShow, onChange, modalData, sendData}) => {
const onHide = (x) => {console.log(x)};

const ModalButton = ({className, onChange, fields, values}) => {
  const [modal, setmodal] = useState({
                                      show    : false,
                                      hovering: false,
                                      fields  : fields
                                    });
  //
  // useEffect(()=>{
  //   // if(modal.show === false) return;
  //
  //   fetch(getUrl('search/index.php', "ModalButton"))
  //     .then ( x=>x.json() )
  //     .then ( x=>{
  //       console.log("SearchSection", x);
  //       setmodal(modal=>({...modal, searchSchema: x}) )
  //     })
  //     .catch( x=>console.error(x) );
  // }, []);

  const parseData = (data, onChange) => {
    console.table(data);
    setmodal({...modal, search: data, show: false});
    if(onChange) onChange({search: data})
  }


    let searchkeys   = [];
    let divkeys      = [];
    let ModalForm    = "no data";
    let types        = [];
    let cities       = [];
    let bedrooms     = [];
    let acres        = [];
    let price        = [];
    let parsedSearch = {
                        City  : "",
                        Type  : "",
                        Beds  : "",
                        Acres : "",
                        Price : "",
                      };

    // if(modal && modal.searchSchema){
    //   console.log("searchSchema is set");
    //
    //   searchkeys = !modal.search
    //                 ? []
    //                 : Object.keys(modal.search);
    //
    divkeys = Object.keys(fields)
      .filter(k=> (values[k] != "Any") )
      .map(
        (k,i)=>(
          /*the | goes on front of the search parameter, so that i need to check only one case: are there more than one? */
          <span key={i}>
            {i==0 ? "" : "\u00A0|\u00A0"}
            {k}: <b>{JSON.stringify(values[k])}</b>
          </span>
        ) // (k,i)=>
      );
    //
    //   types    = ["Any"].concat(Object.keys(modal.searchSchema.types));
    //   cities   = ["Any"].concat(modal.searchSchema.cities);
    //   bedrooms = ["Any", "1-3", "3-5", "5 or More"];
    //   acres    = ["Any", "less than 2000","2000 to 6000","6000 to 10000","10000 or more"];
    //   price    = ["Any", "less than $2M", "$2M", "$3M", "$5M", "$10M or more" ];
    // }

    //https://github.com/react-bootstrap/react-bootstrap/issues/3480#issuecomment-474061945
    // Modal inside div with onClick to allow closing
    return (
      <div className={className} onClick={e => e.stopPropagation()}>
        <button
          type ="button" className="btn btn-secondary w-50"
          onClick={()=>setmodal({...modal, show: true})}
        >
          Refine This Search
        </button>


        <Modal
          centered
          id       ="ModalSearch"
          size     ="xl"
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-90w"
          animation={false}
          show     ={modal.show}
          onHide   ={()=>setmodal({...modal, show: false})}
          onExit   ={()=>setmodal({...modal, show: false})}
          backdrop ={true}
          style    ={{zIndex: "9999"}}
        >
          <JSONForm
            header         ="Filter your Search"
            submitLabel    ="Set Search Parameters"
            fields         ={fields}
            defaultFormData={values}
            sendData       ={onChange
                              ? e=>{console.log("sendData", e); setmodal({...modal, show: false}); onChange({search: e}); }
                              : e=>{console.log("sendData", e); setmodal({...modal, show: false}) }
                            }
          />
        </Modal>
        <div>{divkeys}</div>
      </div>
  );
}; // const ModalButton = ({className, onHide, onShow, onChange, modal, send}) => {
  // modal : {JSON.stringify(Object.keys(modal))}<br/>
  // search: {JSON.stringify(values)}


  // <Modal
  //   centered
  //   animation={false}
  //   show     ={modal.show}
  //   onHide   ={()=>setmodal({...modal, show: false})}
  //   onExit   ={()=>setmodal({...modal, show: false})}
  //   backdrop ={true}
  //   size     ="xl"
  //   style    ={{zIndex: "9999"}}
  //   aria-labelledby="contained-modal-title-vcenter"
  //   dialogClassName="modal-90w"
  // >
export default ModalButton;
