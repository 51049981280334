import React from 'react';

const Content = () => (
  <main className="inside-main">
    <div className="container">
      <div className="align-items-md-start d-md-flex flex-md-row flex-md-wrap justify-content-md-start row">
        <div className="col-md-8">
          <h3 className=" pb-3">About Stephanie</h3>
          <p><strong>VOTED BEST REAL ESTATE AGENT IN WHITEFISH 2015 and 2016!</strong></p>
          <p>&nbsp;Stephanie Sunshine launched her career in private real estate
            acquisition and development in the Flathead Valley in the late 90s and
            earned her real estate license in 2003, joining the National Parks
            Realty team in 2011.
          </p>
          <p>Originally from the San Francisco Bay area, Stephanie is a graduate of
            the University of Arizona where she earned her B.A. in Anthropology.
            After graduating, Stephanie traveled the globe and lived extensively in
            New Zealand and Japan. Her adventurous spirit and love for the great
            outdoors eventually brought her to Whitefish, where she settled in 1993.
          </p>
          <p>Stephanie possesses expert insight into the Flathead Valley lifestyle
            and local culture, specific neighborhoods, demographics, as well as a
            keen understanding of the construction quality of homes, both new and
            old. Having built and sold custom homes, Stephanie thoroughly
            understands permitting, construction, and legal processes and can help
            clients navigate these at both the local and state levels.
          </p>
          <p>Stephanie’s role in your real estate sale or purchase does not revolve
            solely around sealing the deal. She is committed to providing her
            clients the necessary tools to make educated decisions: from
            understanding the vicissitudes of market trends, to identifying the
            property that best suits their needs and visions, to finding qualified
            contractors. Whether a buyer’s or seller’s agent, Stephanie is realistic
            and transparent, she works with integrity, compelling energy, wisdom,
            and superb negotiation skills.
          </p>
          <p>&nbsp;In her free time, Stephanie can be seen skiing, biking, kayaking, kite
            surfing, or hiking on the beautiful trails around Whitefish with her
            husband, John, and their two dogs.
          </p>
          <p>“I invite you to share your real estate goals. Together, we can make your dreams a reality.”</p>
        </div>
        <div className="col-md-4">
          <a href="#"> <img className=" img-thumbnail mb-3 mt-3 w-100" src="img/sunshinemontana/StephanieSunshine-profile-pic01.jpg" alt="" /> </a>
          <p className="font-weight-bold lead text-center">Stephanie Sunshine</p>
          <p className="text-center">Office: (406) 862-0118</p>
          <p className="text-center">Mobile (406) 253-3013</p>
        </div>
      </div>
      <div className=" mb-5 mt-5 pt-5 row">
        <div className="col-md-5 offset-md-1 text-center">
          <p>Stephanie was recently voted one of&nbsp;</p>
          <h5 className="font-weight-bold text-uppercase">America's Best Real Estate Agents</h5>
          <p>by Real Trends Magazine!&nbsp;</p>
        </div>
        <div className="align-content-center align-items-center col-md-5 d-flex flex-row justify-content-center">
          <img src="img/sunshinemontana/RealTrends_logo.png" className="image-fit image-fit-center-center img-fluid" />
        </div>
      </div>
      <div className=" mb-5 mt-5 pb-5 pt-5 row">
        <div className="col-md-6 text-center">
          <a className="btn btn-secondary waves-effect waves-light" href="./property-listings.html">Browse Montana Listings</a>
        </div>
        <div className="col-md-6 text-center">
          <a className="btn btn-secondary waves-effect waves-light" href="./accolades.html">View Client Testimonials</a>
        </div>
      </div>
    </div>
  </main>
);

const data = {
  navbarDisplay: true,
  order : 1 ,
  render: () => <Content />,
  info:   () => ("About"),
  icon:   () => ( <> <i className="far fa-info-circle fa-lg"></i><br/>About </> )
};
export default data;
