import React, {useState, useEffect} from 'react';
import JSONForm  from '../Components/JSONForm';
import Row       from 'react-bootstrap/Row';
import Col       from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {getUrl}    from '../Helpers';

const Contact = () => {
  const [query,    setQuery]    = useState({name: "[blank]", email: "[blank]", message: "[blank]"});
  const [response, setResponse] = useState({message: ""});

  const sendData = (query) => {
    console.log("detail", query);

    fetch(getUrl("email/index.php", "contact"),
      {
        method: "POST",
        body: JSON.stringify(query)
      })
	  .then(x=>x.json())
	  .then(x=>{
              console.log("loading", x );
              setResponse({message: x.result});
	  });
  };

  const updateForm = (event) => {
    console.log("event", event.target.value, event.target.id);
    let newQuery = {...query};
    newQuery[event.target.id] = event.target.value;
    setQuery(newQuery);

    // console.table(newQuery);
  }

  return (
  <main className="inside-main">
  <h1 className="text-center">Get In Touch With Us</h1>
  <section>
    <Container  className="align-items-center justify-content-center ">
      <Row>
        <Col>
        <JSONForm
          header     =  "Send A message"
          submitLabel=  "Send Message"
          sendData   =  {sendData}
          fields     =  {
                          {
                            "name"    : {type: "text",     label: "Your Name",  placeholder: "Enter name..." },
                            "email"   : {type: "email",    label: "Your Email", placeholder: "Enter email..." },
                            "message" : {type: "textarea", label: "Message:",   placeholder: "Enter your message..." }
                          }
                        }
        />
        <h1>
          {response.message}
        </h1>
        </Col>
      </Row>
      <Row className="justify-content-x-center">
        <Col>
        <h4 className="font-weight-bold mb-4 text-uppercase text-center">Offices</h4>
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <h5 className="font-weight-normal mb-2">Whitefish, Montana</h5>
          <p className="">
            601 Spokane Ave.<br/>
            Whitefish, Montana 59937<br/>
            Phone: 406.862.8400
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">

          <h5 className="font-weight-normal mb-2">Bigfork, Montana</h5>
          <p>
            710 Grand Drive<br/>
            Bigfork, Montana 59911<br/>
            Phone: 406.837.1249</p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <h5 className="font-weight-normal mb-2">Eureka, Montana</h5>
          <p>
            124 Riverside Dr. Ste 202<br/>
            Eureka, Montana 59917<br/>
            Phone: 406.297.4700</p>
        </Col>
      </Row>
        <Col className="text-center">

        <h5 className="font-weight-normal mb-2">Toll Free: </h5>
        <ul className="list-unstyled mb-3">
          <li>
            <a href="tel:+0 123-456-789" className="text-dark">866.599.8160</a>
          </li>
        </ul>
        <h5 className="font-weight-bold mb-3 pt-3 text-uppercase">Follow National Parks Realty on Social</h5>
        <div className="d-inline-flex flex-wrap">
          <a href="http://www.facebook.com/pages/National-Parks-Realty/107274379327330" className="btn btn-secondary mr-1 p-2 rounded-circle waves-effect waves-light">
            <svg viewBox="0 0 24 24" fill="currentColor" width="1rem" height="1rem" className="d-block">
              <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path>
            </svg>
          </a>
          <a href="https://twitter.com/NPRealty" className="btn btn-secondary mr-1 p-2 rounded-circle waves-effect waves-light">
            <svg viewBox="0 0 24 24" fill="currentColor" width="1rem" height="1rem" className="d-block">
              <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"></path>
            </svg>
          </a>
          <a href="https://www.instagram.com/nationalparksrealty" className="btn btn-secondary mr-1 p-2 rounded-circle waves-effect waves-light">
            <svg viewBox="0 0 24 24" fill="currentColor" width="1rem" height="1rem" className="d-block">
              <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"></path>
            </svg>
          </a>
        </div>
      </Col>
      </Container>
  </section>
  </main>
  );

};

const data = {
  navbarDisplay: true,
  info:   () => "Contact",
  order : 4 ,
  render: () => <Contact />,
  icon:   () => ( <> <i className="fal fa-phone-square-alt fa-lg"></i><br/>Contact </> )
};
export default data;
