import React from 'react';

const Testimonials = () => (
  <main className="inside-main">
  <div className="container">
    <div className="align-items-md-start d-md-flex flex-md-row flex-md-wrap justify-content-md-start row">
      <div className="col-md-12">
        <h1 className="pb-3 text-center">Testimonials &amp; Awards for Outstanding Service</h1>
        <p>&nbsp;Stephanie Sunshine has been recognized many times by some of the leading voices in the real estate industry for her outstanding service and exemplary work ethic.&nbsp; She also has a long list of complimentary testimonies from satisfied clientelle.</p>
        <p>Take a look at the awards and testimonies&nbsp; given below to see what others have to say about Stephanie's outstanding service and attention to detail.&nbsp;</p>
      </div>
    </div>
    <div className="mt-auto  pt-5 row">
      <div className="col-md-12 ">
        <p className="font-weight-bold lead text-center"><strong>VOTED BEST REAL ESTATE AGENT IN WHITEFISH 2015 and 2016!&nbsp;</strong></p>
      </div>
    </div>
    <div className="award-block row">
      <div className="col-md-5 offset-md-1 text-center">
        <p>Stephanie was recently voted one of&nbsp;</p>
        <h5 className="font-weight-bold text-uppercase">America's Best Real Estate Agents</h5>
        <p>by Real Trends Magazine!&nbsp;</p>
      </div>
      <div className="align-content-center align-items-center col-md-5 d-flex flex-row justify-content-center">
        <img src="img/sunshinemontana/RealTrends_logo.png" className="image-fit image-fit-center-center img-fluid" />
      </div>
    </div>
  </div>
</main>
  );

const data = {
  navbarDisplay: true,
  order : 3,
  info  : () => {return ("Testimonials"); },
  render: () => <Testimonials />,
  icon  : () => ( <> <i className="far fa-award fa-lg"></i><br/>Accolades </> )
};

export default data;
