import React from 'react';

const PageNumber = ({page, lastPage, onClick}) => {
  // force page number into int value bcz sometimes we get 12 or "12" because programming is awesome
  let npage = Math.floor(Number(page));

  const pageButtons = () => (
        [
          npage-3, npage-2, npage-1,
          npage,
          npage+1, npage+2, npage+3
        ]
        .filter(n      => (n>=0 && n<=lastPage) )
        .map(   (n, i) => (
            <li key={i} className={n===npage ? "page-item active" : "page-item"}>
               <button
                 className="page-link"
                 onClick={onClick
                    ? ()=>onClick    ({pageStart: n})
                    : ()=>console.log("change page", n) }>
                 {n+1}
               </button>
            </li>
        ))
    );

  return (
    <>
      <li key="a" className="page-item">
         <button className="page-link " aria-label="Previous"
           onClick={  onClick
                        ? ()=>onClick    ({pageStart: Math.max(0, npage-10) })
                        : ()=>console.log("change page")} >
           <span aria-hidden="true">&laquo;</span> </button>
      </li>
      {pageButtons()}
      <li key="b" className="page-item">
         <button className="page-link " aria-label="Next"
           onClick={  onClick
                        ? ()=>onClick    ({pageStart: Math.min(lastPage, npage+10) })
                        : ()=>console.log("change page")} >
           <span aria-hidden="true">&raquo;</span> </button>
      </li>
    </>
  );
}

const PagingSection = ({page, lastPage, onClick}) => (
  <div className=" d-flex justify-content-center">
    <ul className="pagination" style={{marginBottom: "0px"}}>
      <PageNumber page={page} lastPage={lastPage} onClick={onClick}/>
    </ul>
  </div>
);
export default PagingSection;
