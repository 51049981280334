import React from 'react';

const TabView=({data, images, visibleTab, tabs, onChange})=>{
  // console.log( visibleTab, tabs);

  return(
  <section id="property-listings" role="tabpanel" aria-labelledby="detail-tab" className="property-listings">
    <div className="col-md-12 property-listing-tabs">
      <nav className="nav nav-justified nav-pills text-uppercase">
        {Object.entries(tabs).map(tab=>
          <a
            key          ={tab}
            id           ="detail-tab"
            data-toggle  ="tab"
            aria-selected="true"
            className    ={visibleTab==tab[0]?"active nav-link":"nav-link"}
            onClick      ={onChange? ()=>onChange({tab: tab[0]}) : null }
          >{tab[1].info}</a>
        )}
      </nav>
    </div>

    {tabs && tabs[visibleTab] ? tabs[visibleTab].render(data, images, onChange) : JSON.stringify(visibleTab) }
  </section>
);
}

export default TabView
