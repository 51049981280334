const appConfig = {
  showLogo: false,
  owner: "20040305233038630577000000",
  name:  "Stephanie Sunshine",
  email: "sunshine@nprmt.com",
  header: {
    logoUrl: "img/svg/NPR-F_stackedsidexside_white_jimedit.svg",
  },
  footer: {
    imageUrl : "img/sunshinemontana/SVG/Sunshine-Montana-Real-Estate.svg",
    items: [
      { icon: "far fa-map-marker fa-lg", label: "Address", body: ["601 Spokane Avenue",     "Whitefish, Montana","59937"] },
      { icon: "far fa-phone fa-lg",      label: "Phone",   body: ["Office: (406) 862-0118", "Mobile: (406) 253-3013"] },
      { icon: "far fa-envelope fa-lg",   label: "Email",   body: ["sunshine@nprmt.com"] }
    ]
  },
  phone  : "406.253.3013"
}
export default appConfig;
