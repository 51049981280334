import React from 'react';

const ContactBlock = () => (
<section id="cta-block" className="cta-block" style={{backgroundImage: "url('img/sunshinemontana/jen-theodore-CiMITAJtb6I-unsplash.jpg')"}}>
  <section className="container pb-5 pt-5">
    <div className="pb-5 pt-5 row">
      <div className="col-lg-9 col-xl-8 ml-auto mr-auto mx-auto pb-5 pt-5">
        <h3 className="font-weight-bold mb-3 text-uppercase">Work With Stephanie</h3>
        <p className="font-weight-light lead mb-4 text-uppercase">As a leading real estate agent representing the greater Flathead Valley for National Parks Realty, Stephanie Sunshine offers deep market expertise and helpful local knowledge and insight.&nbsp; Contact her today to start your Northwest Montana property or home journey.</p>
        <a href="./contact" className="btn btn-outline-light waves-effect waves-light">Get In Touch</a>
      </div>
    </div>
  </section>
</section>
);
export default ContactBlock;
