import React from 'react';
import {
  Link,
} from "react-router-dom";

const WelcomeBlock = () => (
  <section id="welcome-block" className="welcome-block">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="col-md-12">
            <img className="w-100 img-thumbnail" src="img/sunshinemontana/StephanieSunshine-profile-pic01.jpg" alt="Stephanie Sunshine" />
          </div>
        </div>
        <div className="col-md-8">
          <h4 className="welcome-block-headline"><b>Stephanie Sunshine</b></h4>
          <p>Stephanie possesses expert insight into the Flathead Valley lifestyle and local culture, specific neighborhoods, demographics, as well as a keen understanding of the construction quality of homes, both new and old. Having built and sold custom homes, Stephanie thoroughly understands permitting, construction, and legal processes and can help clients navigate these at both the local and state levels.</p>
          <a href="contact" role="button" type="button" className="btn btn-secondary">Contact Stephanie</a>
        </div>
      </div>
    </div>
  </section>
);
export default WelcomeBlock;
