import React, {useState, useEffect} from 'react';

import {getUrl}       from '../../Helpers';
import appConfig      from '../../appConfig';

import MapSection     from './MapSection';
import ListingSection from './ListingSection';
import TabView        from './TabView';

import SearchSection  from './SearchSection';
import PagingSection  from './PagingSection';
import SearchDropdown from '../../Components/SearchDropdown';

import Dropdown       from 'react-bootstrap/Dropdown';
import Button         from 'react-bootstrap/Dropdown';

const tabs = {
  "list": {
    render: (listingData, images, onChange)=><ListingSection listingData={listingData} images={images} onChange={onChange} />,
    info  : "Property List"
  },
  // "map" : {
  //   render: (listingData, images, onChange)=><MapSection     listingData={listingData} images={images} onChange={onChange} />,
  //   info  :  "Properties on Map"
  // },
};

const Component = ({query}) => {


  const [state, setstate] = useState({
    searchLimit : (query && query.searchLimit                        ? query.searchLimit : "" ),
    filterBy    : (query && query.filterBy                           ? query.filterBy    : "" ),
    pageStart   : (query && query.pageStart                          ? query.pageStart   : "" ),
    activetab   : (query && query.activetab && tabs[query.activetab] ? query.activetab   : Object.keys(tabs)[0] ), //if not set or not found, default to first in list
    owner       : (query && query.owner                              ? query.owner       : "All" ),
    search      : {
                    Acres      : (query && query.Acres      ? query.Acres      : "Any"),
                    Beds       : (query && query.Beds       ? query.Beds       : "Any"),
                    City       : (query && query.City       ? query.City       : "Any"),
                    Price      : (query && query.Price      ? query.Price      : "Any"),
                    Type       : (query && query.Type       ? query.Type       : "Any"),
                    // Priceend   : (query && query.Priceend   ? query.Priceend   : "Any"),
                    // Pricestart : (query && query.Pricestart ? query.Pricestart : "Any"),
                  },
  });

  // console.log(query);

  //TODO this is messy: The state holds the current config inside the page
  //  then, once it changes, requests doFetch with the state query params assigned
  //  but changing state from doFetch calls state change -> infinite loop
  //  we override by keeping a "read only" copy of the latest received data from server in list/setlist
  //  BUG: for some reason on the end of the list (pageStart = ~674) it stops synchronizing due to php server returning the actual end of the list
  //    and then tries to set the pageStart to the end of the list and wont work
  const [doFetch, setdoFetch] = useState({do:false});
  const [list,    setlist]    = useState(state);
  const [images,  setimages]  = useState({});

  useEffect(()=>{
    if(doFetch.do == false) return;

    console.log("doFetch", doFetch);
    setdoFetch({do: false}); // ??? a flag of whether we need to refresh i guess

    fetch(getUrl("index.php?"+(doFetch.do||""), "property-listings"))
      .then(x=>x.json())
      .then(x=>{
        // console.log("doFetch loading", x);

        //TODO: make something to verify listing and state have the same data
        x.activetab = state.activetab;
        setlist(x);
        window.scrollTo(0,0);

        fetch(getUrl("images/index.php?", "property-listings"),
          { method: 'POST', body: JSON.stringify(x.data.map(mls=>mls.mls_number))}
        )
        .then(x=>x.json())
        .then(x=>{
          // console.log(x);
          setimages(x);
        })
        .catch(e => console.error(e) );
      })
      .catch(e => {
        console.error(e);
      });
      ;
  }, [doFetch]);


  useEffect(()=>{
    console.log("list", list);

    let l = new URLSearchParams({
      pageStart   : list.pageStart,
      searchLimit : list.searchLimit,
      filterBy    : list.filterBy,
      activetab   : list.activetab,
      owner       : list.owner,
    });
    Object.entries(list.search).forEach( (k)=>l.append(k[0], k[1]) );
    // if(list.owner) l.owner = list.owner;

    window.history.pushState(list, '', `?${l.toString()}`)
  }, [list]);

//testing a test

  useEffect(()=>{
    //this is the main set of url params, all url params should be set here
    let l = new URLSearchParams({
      owner          : state.owner,
      pageStart      : state.pageStart,
      searchLimit    : state.searchLimit,
      filterBy       : state.filterBy,
      activetab      : state.activetab,
    });

    if(state.search){
      // console.log("state.search", state.search);
      Object.entries(state.search).forEach( (k)=>l.append(k[0], k[1]) );
    } else {
      console.log("no search here ");
    }

    console.log("will load?",!!doFetch.do, l.toString());
    //if previous fetch l is the same as this l, then we just set l
    // otherwise we set doFetch as l
    if(doFetch.do == false){
      setdoFetch({do: l});
    }
  },[state]);

  useEffect(() => {
    // console.log("change? ", query==state.activetab);
    if(query && query.activetab) setstate({...state, activetab: query.activetab});
  }, [query] );

  // const change = ({change={}, search, owner, pageStart }) => {
  const change = (nextState) => {
    console.log(nextState);
    if(!nextState)        return;
    if(!nextState.change) nextState.change={};

    let newState = {
      ...state,
      owner       : (nextState.owner=="yes"       ? appConfig.owner              : "All" ),
      pageStart   : (nextState.pageStart          ? nextState.pageStart          : list.pageStart),
      searchLimit : (nextState.searchLimit        ? nextState.searchLimit        : list.searchLimit),
      filterBy    : (nextState.filterBy           ? nextState.filterBy           : list.filterBy),
      activetab   : (nextState.tab                ? nextState.tab                : state.activetab),
      search      : (nextState.search             ? nextState.search             : list.search),
      // search      : (change.search      ? change.search      : state.search),
    };
    console.log("Component change", nextState, newState);
    setstate( newState );
  }

  if(state.error) return <div  style={{paddingTop:"100px"}}> <pre>oops error:{JSON.stringify(state.error, null, 2)}</pre> </div>

  return (
    <main className="inside-main">
      <div className="container">

        <div className="row">
          <h1 className="text-center col-md-12">
            {list.owner=="All" ? "All Northwest Montana" :  appConfig.name } Listings
          </h1>
        </div>

        <SearchSection
          className="pt-3  mb-3 align-center"
          state   ={list}
          onChange={change}
          agentName={state.owner=="All" ? appConfig.name : "All Northwest Montana"}
        />

        <TabView
          data      ={list}
          images    ={images}
          tabs      ={tabs}
          visibleTab={state.activetab}
          onChange  ={change}
        />

      </div>
    </main>
  );
};

export default Component;
