/* Helper functions */

export const getUrl = (path, tag="untagged") => {

  let url = (process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:8080/api/v1/${path}`    //local
    :                      "/api/v1/"+path ); //remote
  console.log(tag, ": trying ", url);
  return url;
}
