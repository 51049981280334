import React from 'react';
import {
  Link,
} from "react-router-dom";

const WelcomeBlock = () => (
  <header>
    <section className="hero-block" id="hero-block">
      <section className="container">
        <div className="row">
          <div className="col-lg-10 header-content">
            <h5 className="text-uppercase text-white">Stephanie Sunshine</h5>
            <h1 className="hero-block-title">Real Estate in Whitefish, Montana</h1>
            <p className="mb-0 hero-block-content ">Ranked #9 By Sales Volume in the State of Montana</p>
            <img className="w-50 " src="img/sunshinemontana/Americas-Best-2020.png" alt="Stephanie Sunshine" />

            <Link style={{width: "90%"}} to="property_listings" className="btn btn-lg feature-link">PROPERTIES</Link>
          </div>
        </div>
      </section>
    </section>
  </header>
);
export default WelcomeBlock;
